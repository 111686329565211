import { Component, OnInit } from '@angular/core';
import { logging } from 'selenium-webdriver';

@Component({
  selector: 'bt-alcohol',
  templateUrl: './alcohol.component.html',
  styleUrls: ['./alcohol.component.css']
})
export class AlcoholComponent implements OnInit {
  formData = {og:1.061,sg:1.021}
  level
  plato
  platoOG

  changeHandler(){
    this.level = this.calcLevel(this.formData.og, this.formData.sg);
    this.plato = this.calcPlato(this.formData.sg);
    this.platoOG = this.calcPlato(this.formData.og);
  }
  constructor() { }

  ngOnInit() {
    this.level =this.calcLevel(this.formData.og, this.formData.sg);
    this.plato = this.calcPlato(this.formData.sg);
    this.platoOG = this.calcPlato(this.formData.og);
  }

  calcLevel(og, sg){
    let lvl = (og-sg)*131.25;
    return lvl.toFixed(2);


    //     SG = 1+ (plato / (258.6 – ( (plato/258.2) *227.1) ) )
  }

  calcPlato(sg){
    // Plato = (-1 * 616.868) + (1111.14 * sg) – (630.272 * sg^2) + (135.997 * sg^3)
    let p = (-1 * 616.868) + (1111.14 * sg) - (630.272 * (sg*sg)) + (135.997 * (sg*sg*sg));
    return p.toFixed(1);
  }


}
