import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'bt-platotable',
  templateUrl: './platotable.component.html',
  styleUrls: ['./platotable.component.css']
})
export class PlatotableComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
